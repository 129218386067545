import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { db } from "../firebase/firebase";
import AdsComponent from "../components/AdsComponent";
import {
  collection,
  addDoc,
  getDocs,
  where,
  query,
  limit,
  orderBy,
  startAfter,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Body = styled.div`
  width: 75%;
  max-width: 1200px;
  min-height: 100vh;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Item = styled.div`
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  :hover {
    cursor: pointer;
  }
`;

const Games = () => {
  //get the url path and store in a const
  const location = useLocation();
  const [platform, setPlatform] = useState(null);
  const [games, setGames] = useState([]);
  const getConsoleData = async () => {
    let gamesCpy = [...games];
    const platformData = await getDocs(
      query(
        collection(db, "consoles"),
        where("slug", "==", location.pathname.slice(1))
      )
    );
    setPlatform(platformData.docs[0].data());
    const gamesData = await getDocs(
      query(
        collection(db, "roms"),
        where("console", "==", platformData.docs[0].data().slug),
        orderBy("name", "asc"),
        limit(10)
      )
    );
    gamesCpy = [...gamesCpy, ...gamesData.docs.map((doc) => doc.data())];
    setGames(gamesCpy);
  };

  const getMoreGames = async () => {
    if (!platform) return;
    if (!games.length) return;
    const lastGame = games.length - 1;

    const gamesData = await getDocs(
      query(
        collection(db, "roms"),
        where("console", "==", platform.slug),
        orderBy("name", "asc"),
        startAfter(games[lastGame].name),
        limit(10)
      )
    );
    setGames([...games, ...gamesData.docs.map((doc) => doc.data())]);
  };

  useEffect(() => {
    getConsoleData();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Body>
        {platform ? (
          <>
            <h1 style={{ marginBottom: "20px" }}>
              Download {platform.console} ROMS
            </h1>
            <p style={{ marginBottom: "20px" }}>{platform.text}</p>
          </>
        ) : (
          <>
            <h1>
              <Skeleton />
            </h1>
            <Skeleton count={5} />
          </>
        )}
        <p style={{ marginBottom: "20px" }}></p>
        {games.length ? (
          <InfiniteScroll
            dataLength={games.length}
            next={() => getMoreGames()}
            hasMore={games.length ? true : false}
            loader={<Skeleton count={5} />}
          >
            <div style={{ width: "100%", height: "120px" }}>
              <AdsComponent />
            </div>
            {games.map((game, index) => (
              <div key={index}>
                <Item>
                  <a href={game.url} target="_blank">
                    {game.name}
                  </a>
                  <p>{game.size}</p>
                </Item>
                {index !== 0 && index % 20 === 0 ? (
                  <div style={{ width: "100%", height: "120px" }}>
                    <AdsComponent />
                  </div>
                ) : null}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <Skeleton count={5} />
        )}
      </Body>
    </div>
  );
};

export default Games;
