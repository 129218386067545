import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: 75%;
  max-width: 1200px;
  min-height: 100vh;
`;

const Terms = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Body>
        <h1>Terms of Use</h1>
        <p>
          Welcome to ROMSGURU. By accessing and using our website, you agree to
          comply with the following terms and conditions. These terms serve to
          protect both you and ROMSGURU, ensuring a safe and respectful
          environment for all users. Please read them carefully before using our
          services.
        </p>
        <h3>1. Acceptance of Terms:</h3>
        <p>
          By using ROMSGURU, you signify your agreement to these Terms of Use
          and our Privacy Policy. If you do not agree to these terms, please do
          not use our website.
        </p>
        <h3>2. Intellectual Property Rights:</h3>
        <p>
          All content on ROMSGURU, including text, graphics, logos, and
          downloads, is owned by us or our content suppliers and is protected by
          international copyright and intellectual property laws. You may not
          use content from our site without permission from the copyright owner.
        </p>
        <h3>3. Use of Service:</h3>
        <p>
          ROMSGURU provides a service for downloading ROMs of classic video
          games. These ROMs are intended for personal use only, and not for
          redistribution or commercial use. We require that you own a physical
          copy of the game you are downloading a ROM for, to support the
          original creators.
        </p>
        <h3>4. User Conduct:</h3>
        <p>
          Users are expected to use ROMSGURU responsibly and legally. Engaging
          in activities that threaten the website or other users, such as
          spreading malware, is strictly prohibited and may result in a ban from
          our service.
        </p>
        <h3>5. Disclaimer of Warranties:</h3>
        <p>
          The service is provided "as is". ROMSGURU makes no warranties,
          expressed or implied, and hereby disclaims and negates all other
          warranties, including without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of
          rights.
        </p>
        <h3>6. Limitations of Liability:</h3>
        <p>
          ROMSGURU or its suppliers will not be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption) arising out of the use or inability to
          use the materials on ROMSGURU's website.
        </p>
        <h3>7. Revisions and Errata:</h3>
        <p>
          The materials appearing on ROMSGURU's website could include technical,
          typographical, or photographic errors. ROMSGURU does not warrant that
          any of the materials on its website are accurate, complete, or
          current. We may make changes to the materials contained on its website
          at any time without notice.
        </p>
        <h3>8. Links:</h3>
        <p>
          ROMSGURU has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by ROMSGURU of the
          site. Use of any such linked website is at the user's own risk.
        </p>
        <h3>9. Site Terms of Use Modifications:</h3>
        <p>
          ROMSGURU may revise these terms of use for its website at any time
          without notice. By using this website, you are agreeing to be bound by
          the then-current version of these Terms of Use.
        </p>
        <h3>10. Governing Law:</h3>
        <p>
          Any claim relating to ROMSGURU's website shall be governed by the laws
          of the website owner's jurisdiction without regard to its conflict of
          law provisions.
        </p>
        <h3>Contact Us:</h3>
        <p>
          If you have any questions about these Terms of Use, please contact us
          at support@romsguru.com.
        </p>
        <p>
          Thank you for choosing ROMSGURU. Let's enjoy the world of retro gaming
          responsibly and respectfully.
        </p>
      </Body>
    </div>
  );
};

export default Terms;
