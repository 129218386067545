import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Layout from "./pages/Layout";
import Games from "./pages/[slug]";
import { createMemoryHistory } from "history";

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createMemoryHistory();
root.render(
  <BrowserRouter location={history.location} navigator={history}>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path=":id" element={<Games />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<Terms />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
reportWebVitals();
