import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: 75%;
  max-width: 1200px;
  min-height: 100vh;
`;

const About = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Body>
        <h1>About us</h1>
        <p>
          Welcome to ROMSGURU, your ultimate destination for reliving the glory
          days of gaming! We're a passionate team of retro gaming enthusiasts
          committed to preserving the rich history and excitement of classic
          video games. Our website is a treasure trove of ROMs for consoles from
          the past, including the NES, SNES, Game Boy, Sega Genesis, N64, and
          many more.
        </p>
        <p>
          At ROMSGURU, we believe that classic games should be accessible to
          everyone, everywhere, without the barriers of obsolete hardware.
          That's why we've dedicated ourselves to compiling the most extensive
          and meticulously curated collection of ROMs on the internet. From
          beloved blockbusters to rare gems, our library is continuously updated
          to ensure you can find and enjoy the games of yesteryears.
        </p>
        <p>
          <strong>Our Mission:</strong> To keep the spirit of retro gaming alive
          and kicking for current and future generations. We aim to provide a
          safe, user-friendly platform where gamers can download their favorite
          ROMs for free, share their experiences, and connect with a like-minded
          community.
        </p>
        <p>
          <strong>Safety & Quality:</strong> Your safety is our priority. All
          files on ROMSGURU are rigorously checked for integrity and
          authenticity, ensuring you get safe and smooth gameplay without any
          unwelcome surprises.
        </p>
        <p>
          <strong>Join the Community:</strong> Beyond just downloads, ROMSGURU
          is a place to share, discuss, and celebrate retro gaming culture.
          Whether you're seeking advice, game recommendations, or just want to
          reminisce about the good old days, our community forum is the perfect
          place to connect with fellow retro gaming fans.
        </p>
      </Body>
    </div>
  );
};

export default About;
