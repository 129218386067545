import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: 75%;
  max-width: 1200px;
  min-height: 100vh;
`;

const Contact = () => {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Body>
        <h1>Contact us</h1>
        <p>
          At ROMSGURU, your thoughts, questions, and feedback are invaluable to
          us as we strive to create the ultimate online retro gaming repository.
          Whether you're looking for a specific ROM, need support with a
          download, or simply want to share your retro gaming stories, we're
          here to listen and assist.
        </p>
        <p>
          <strong>Email Us:</strong>For general inquiries, suggestions, or
          feedback, drop us an email at support@romsguru.com. We aim to respond
          within 24-48 hours.
        </p>
        <p>
          <strong>Note:</strong> While we're passionate about retro gaming,
          please remember that ROMSGURU does not support or condone piracy.
          Ensure that you own a legitimate copy of any game you download.
        </p>
        <p>
          Thank you for being a part of ROMSGURU. Together, let's keep the
          legacy of classic gaming alive for generations to come!
        </p>
      </Body>
    </div>
  );
};

export default Contact;
