import { Outlet, Link } from "react-router-dom";
import styled from "styled-components";
import { db } from "../firebase/firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { useEffect } from "react";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f2f2f2;
  border-bottom: 1px solid #e0e0e0;
  .logo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  ul {
    display: flex;
    gap: 1rem;
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    li {
      :hover {
        text-decoration: underline;
      }
    }
  }
  a {
    text-decoration: none;
    color: #333;
  }
`;
const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f2f2f2;
  border-top: 1px solid #e0e0e0;
  margin-top: 2rem;
  p {
    margin: 0;
  }
`;

const Layout = () => {
  return (
    <>
      <Header>
        <a href="/">
          <div className="logo">
            <img
              src="https://th.bing.com/th/id/OIP.0JXLA9YOMhwa32vttRsHpQHaHa?rs=1&pid=ImgDetMain"
              alt="logo"
              width="50"
              height="50"
            />
            <h1>Roms Guru</h1>
          </div>
        </a>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About us</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/terms">Terms of use</Link>
          </li>
        </ul>
      </Header>
      <Outlet />

      <Footer>
        <p>&copy; 2024 Roms Guru</p>
      </Footer>
    </>
  );
};

export default Layout;
