import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { db } from "../firebase/firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { games } from "../data/games/wii";
import slugify from "slugify";
import Skeleton from "react-loading-skeleton";
import AdsComponent from "../components/AdsComponent";

const Body = styled.div`
  width: 75%;
  max-width: 1200px;
  min-height: 100vh;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  width: 100%;
  a {
    text-decoration: none;
    color: #333;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2;
  margin-bottom: 20px;
  width: 300px;
  :hover {
    cursor: pointer;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 170px;
    object-fit: cover;
  }
  h2 {
    margin-bottom: 1rem;
  }
`;

const Home = () => {
  const [consoles, setConsoles] = useState([]);
  const getConsoleData = async () => {
    const data = await getDocs(collection(db, "consoles"));
    setConsoles(data.docs.map((doc) => doc.data()));
  };

  // create a function that will add all games to the database from the games array with the id being a slug made with slugify
  const addGames = async () => {
    console.log("starting");
    let cp = games.map((game) => {
      return { ...game, slug: slugify(game.name), console: "nintendo-wii" };
    });
    cp.forEach(async (game) => {
      await addDoc(collection(db, "roms"), game);
    });
    console.log("finished");
  };

  useEffect(() => {
    getConsoleData();
  }, []);
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Helmet>
        <title>Download Free ROMS and ISOS | Roms Guru</title>
        <meta
          name="description"
          content="Download Roms and Isos Games for free. We have a large collection of ROMs and Isos exclusively for download."
        />
      </Helmet>
      <Body>
        <p>
          Welcome to Roms Guru, your go-to spot for free ROM downloads! Dive
          into nostalgia with classics from NES, SNES, GBA, and more. Our site
          offers easy navigation, safe downloads, and a vast library of games.
          Join our community, find your favorites, and start playing today!
        </p>
        {/* <button onClick={() => addGames()}>get game</button> */}
        <div style={{ width: "100%", height: "120px" }}>
          <AdsComponent />
        </div>
        {consoles.length ? (
          <CardsContainer>
            {consoles.map((item, index) => (
              <a href={`/${item.slug}`}>
                <Card key={index}>
                  <h2>{item.console}</h2>
                  <p>{item.text}</p>
                </Card>
              </a>
            ))}
          </CardsContainer>
        ) : (
          <Skeleton count={3} height={200} />
        )}
        <div style={{ width: "100%", height: "120px" }}>
          <AdsComponent />
        </div>
      </Body>
    </div>
  );
};

export default Home;
